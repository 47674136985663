import React from 'react'

const ChangeAPI = ({API, setAPI, showApiOptions}) => {

    const updateApiHandle = (api) => {
        showApiOptions(false)
        setAPI(api)
    }

  return (
    <div className="text-center mt-5">
        <h2>Seem like your API limit is exhausted</h2>
        <h5>Try to switch API <small>(Total limit for each API is 21)</small> </h5>

        <div className="dropdown mt-4">

            <button className="btn btn-danger dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="bi bi-gear-fill"></i> Select API
            </button>
            <ul className="dropdown-menu">
                <li>
                    <button className={`dropdown-item ${API === 'ApiOne' && 'active'}`}
                        onClick={()=>updateApiHandle('ApiOne')}>
                        API 1
                    </button>
                </li>
                <li>
                    <button className={`dropdown-item ${API === 'ApiTwo' && 'active'}`}
                        onClick={()=>updateApiHandle('ApiTwo')}>
                        API 2
                    </button>
                </li>
                <li>
                    <button className={`dropdown-item ${API === 'ApiThree' && 'active'}`}
                        onClick={()=>updateApiHandle('ApiThree')}>
                        API 3
                    </button>
                </li>
                <li>
                    <button className={`dropdown-item ${API === 'ApiFour' && 'active'}`}
                        onClick={()=>updateApiHandle('ApiFour')}>
                        API 4
                    </button>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default ChangeAPI