import axios from "axios";

const BASE_URL = 'https://newscatcher.p.rapidapi.com/v1/'

const options1 = {
    params: {media: 'True'},
    headers: {
      'X-RapidAPI-Key': 'f55207cf63msh5938e34558d14e0p17b6b8jsnf11b190d1889',
      'X-RapidAPI-Host': 'newscatcher.p.rapidapi.com'
    }
  };

export const fetchFromAPI1 = async (url) => {
    const data = await axios.get(`${BASE_URL}${url}`, options1 )
    
    return data;
}

  
const options2 = {
  params: {media: 'True'},
  headers: {
    'X-RapidAPI-Key': '731b88d0bemsh26d411beb50247cp1fcb77jsnfe517306d693',
    'X-RapidAPI-Host': 'newscatcher.p.rapidapi.com'
  }
};

export const fetchFromAPI2 = async (url) => {
    const data = await axios.get(`${BASE_URL}${url}`, options2 )
    
    return data;
}

  
const options3 = {
  params: {media: 'True'},
  headers: {
    'X-RapidAPI-Key': '8f3faed28dmshee06e4d8c460ac3p19ab03jsn53fb4eb68da7',
    'X-RapidAPI-Host': 'newscatcher.p.rapidapi.com'
  }
};

export const fetchFromAPI3 = async (url) => {
    const data = await axios.get(`${BASE_URL}${url}`, options3 )
    
    return data;
}

  
const options4 = {
  params: {media: 'True'},
  headers: {
    'X-RapidAPI-Key': 'd529e7ab1fmsh2a68748eac3fdc0p166ee8jsn3c9fc363c6be',
    'X-RapidAPI-Host': 'newscatcher.p.rapidapi.com'
  }
};

export const fetchFromAPI4 = async (url) => {
    const data = await axios.get(`${BASE_URL}${url}`, options4 )
    
    return data;
}