
const NewsItem = ({ title, description, imageUrl, newsUrl, author, date, source }) => {

    const fallbackImg = 'https://dummyimage.com/600x400/212529/ffc107.png&text=DailyNewsHunt'

    return (
      <>
        <div className="card h-100">
          <span className="position-absolute top-0 end-0 translate-middle-y badge rounded-pill bg-danger me-2">
            {source}
          </span>
          <img src={imageUrl ? imageUrl : fallbackImg} className="card-img-top" alt="..." loading='lazy' />
          <div className="card-body d-flex flex-column">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">
              {description}
            </p>
            <p className="card-text small">
              <i className="bi bi-person-fill me-1 text-danger"></i>
              <small className="text-muted"> By {author ? author : 'Unknown'}</small>
              <span className="mx-1 text-grey">|</span>
              <i className="bi bi-clock-fill me-1 text-danger"></i>
              <small className="text-muted"> {new Date(date).toGMTString()}</small>
            </p>
            <a href={newsUrl} target='_blank' className="btn btn-danger btn-sm mt-auto">Read More</a>
          </div>
        </div>
      </>
    )
  }

export default NewsItem