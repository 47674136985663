import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import { Navbar, News } from './Components';
import LoadingBar from 'react-top-loading-bar'

import {categories} from './utils/constants'

function App() {

  let defaultLanguage = JSON.parse(localStorage.getItem('language'))
  if(defaultLanguage == null)  defaultLanguage = {name:'English', shortName:'en'}

  const [language, setLanguage] = useState(defaultLanguage)

  useEffect(()=>{
     localStorage.setItem('language',JSON.stringify(language))
  },[language])


  let defaultAPI = localStorage.getItem('api')
  if(defaultAPI == null) defaultAPI = 'ApiOne'
  const [Api, setApi] = useState(defaultAPI)
  
  useEffect(()=>{
    localStorage.setItem('api',Api)
  },[Api])
  
  
  let pageSize = 5  

  const [progress, setProgress] = useState(0)

  return (
    <>
    <BrowserRouter>
      <Navbar getCategory='' language={language} setLanguage={setLanguage}/>
      <LoadingBar height={2} color='#ffc107' progress={progress}/>
      <Routes>
        <Route path='/' exact element={<News key='home' pageSize={pageSize} topic='' lang={language} title='All' Api={Api} setApi={setApi} setProgress={setProgress}/>}/>
        {
          categories.map( route =>
            <Route key={route} exact path={`/${route}`} element={<News key={route} setProgress={setProgress}  pageSize={pageSize} Api={Api} setApi={setApi} topic={route} lang={language} />}/>
          )
        }
        <Route path='/search/:query' exact element={<News pageSize={pageSize} search='true' lang={language} title='search' Api={Api} setApi={setApi} setProgress={setProgress}/>}/>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
