import {ChangeAPI, NewsItem, Spinner} from './'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import {fetchFromAPI1, fetchFromAPI2, fetchFromAPI3, fetchFromAPI4 } from '../utils/fetchFromAPI'
import { useParams } from 'react-router-dom'

const News = (props) =>{
    
    const capitalizeFirstLetter = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const params = useParams()
    const {query} = params

    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)

    let apiQuery = `latest_headlines?lang=${props.lang.shortName}&topic=${props.topic}`
    if(props.search) apiQuery = `search_free?lang=${props.lang.shortName}&q=${query}`
    
    let pageTitle = props.topic ? capitalizeFirstLetter(props.topic) : 'Home'
    if(props.search) pageTitle = `Search results found for - ${capitalizeFirstLetter(query)}`
    
    const [totalResults, setTotalResults] = useState(0)
    
    const [showApiOptions, setShowApiOptions] = useState(false)
    
    useEffect(()=>{        
        updateNews()
        if(props.search){
            document.title = `${capitalizeFirstLetter(query)} | DailyNewsHunt `
        }else{
            document.title = `${capitalizeFirstLetter(props.topic)} | DailyNewsHunt `
        }
    },[])
    
    
    useEffect(()=>{
        updateNews()
        document.documentElement.lang = props.lang.shortName
    },[props.lang, props.topic, props.Api, query])
    
    const updateNews = () => {
        props.setProgress(10)

        setLoading(true)

        if(props.Api == 'ApiTwo'){
            fetchFromAPI2(apiQuery)
            .then((data)=>{            
                props.setProgress(30)
                setLoading(false)
                setNews(data.data.articles)
                props.setProgress(100)
            })
            .catch(()=>{
                // setLoading(false)
                // setShowApiOptions(true)
                props.setProgress(100)
                props.setApi('ApiThree')
            });
        }
        else if(props.Api == 'ApiThree'){
            fetchFromAPI3(apiQuery)
            .then((data)=>{            
                props.setProgress(30)
                setLoading(false)
                setNews(data.data.articles)
                props.setProgress(100)
            })
            .catch(()=>{
                // setLoading(false)
                // setShowApiOptions(true)
                props.setProgress(100)
                props.setApi('ApiFour')
            });
        }
        else if(props.Api == 'ApiFour'){
            fetchFromAPI4(apiQuery)
            .then((data)=>{            
                props.setProgress(30)
                setLoading(false)
                setNews(data.data.articles)
                props.setProgress(100)
            })
            .catch(()=>{
                setLoading(false)
                setShowApiOptions(true)
                props.setProgress(100)
            });
        }
        else {
            fetchFromAPI1(apiQuery)
            .then((data)=>{            
                props.setProgress(30)
                setLoading(false)
                setNews(data.data.articles)
                props.setProgress(100)
            })
            .catch(()=>{
                // setLoading(false)
                // setShowApiOptions(true)
                props.setProgress(100)
                props.setApi('ApiTwo')
            });
        }
    }
    

    
    
    // const fetchMoreData = async () => {
    //     props.setProgress(0)
    //     setPage(page+1)
    //     const url = `https://newsapi.org/v2/top-headlines?topic=${props.category}&country=${props.country}&apiKey=${props.apiKey}&page=${page+1}&pageSize=${props.pageSize}`
    //     props.setProgress(50)
    //     let data = await fetch(url)
    //     data = await data.json()
    //     props.setProgress(70)
    //     // console.log(parsedData)
    //     setNews(news.concat(data.articles))
    //     setTotalResults(data.totalResults)
    //     props.setProgress(100)
    // };


    return (
      <>

        <h2 className="my-5 text-center">
            {pageTitle}
        </h2>

        {loading && <Spinner/> }

        {
            showApiOptions &&
            <ChangeAPI API={props.Api} setAPI={props.setApi} showApiOptions={setShowApiOptions}/>
            
        }

        {
            !loading && 
        <div className="container mb-4">
            <div className="row">
                {
                    news.map(item =>
                        <div key={item._id} className="col-12 col-sm-6 col-md-4 mb-4">
                            <NewsItem
                                title={item.title ? item.title : ''}
                                description={item.summary? item.summary.slice(0,88) : ''}
                                imageUrl={item.media}
                                newsUrl={item.link}
                                author={item.author}
                                date={item.published_date}
                                // source={item.source.name}
                            />
                        </div>
                    )

                }
            </div>
        </div>
        }

      </>
    )
  }

  
  News.defaultProps = {
    pageSize: 10,
    topic: ''
}

News.propTypes = {
    pageSize: PropTypes.number,
    topic: PropTypes.string,
}

export default News