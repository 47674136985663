export const categories = ['sport', 'tech', 'world', 'finance', 'politics', 'business', 'economics', 'entertainment','beauty','travel','music','food','science',]

export const languages = [
    {'name' : 'Afrikaans', 'shortName' : 'af', 'type':'int'},
    {'name' : 'Bulgarian', 'shortName' : 'bg', 'type':'int'},
    {'name' : 'Bengali', 'shortName' : 'bn', 'type':'local'},
    {'name' : 'German', 'shortName' : 'de', 'type':'int'},
    {'name' : 'Greek', 'shortName' : 'el', 'type':'int'},
    {'name' : 'English', 'shortName' : 'en', 'type':'int'},
    {'name' : 'Spanish', 'shortName' : 'es', 'type':'int'},
    {'name' : 'Finnish', 'shortName' : 'fi', 'type':'int'},
    {'name' : 'French', 'shortName' : 'fr', 'type':'int'},
    {'name' : 'Gujarati', 'shortName' : 'gu', 'type':'local'},
    {'name' : 'Hindi', 'shortName' : 'hi', 'type':'local'},
    {'name' : 'Italian', 'shortName' : 'it', 'type':'int'},
    {'name' : 'Japanese', 'shortName' : 'ja', 'type':'int'},
    {'name' : 'Korean', 'shortName' : 'ko', 'type':'int'},
    {'name' : 'Malayalam', 'shortName' : 'ml', 'type':'local'},
    {'name' : 'Marathi', 'shortName' : 'mr', 'type':'local'},
    {'name' : 'Nepali', 'shortName' : 'ne', 'type':'int'},
    {'name' : 'Punjabi', 'shortName' : 'pa', 'type':'local'},
    {'name' : 'Russian', 'shortName' : 'ru', 'type':'int'},
    {'name' : 'Tamil', 'shortName' : 'ta', 'type':'local'},
    {'name' : 'Telugu', 'shortName' : 'te', 'type':'local'},
    {'name' : 'Ukrainian', 'shortName' : 'uk', 'type':'int'},
    {'name' : 'Vietnamese', 'shortName' : 'vi', 'type':'int'},
    ]