import { useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import {categories, languages } from '../utils/constants'

const Navbar = ({language, setLanguage}) => {

  const navigate = useNavigate()
    
  const [searchQuery, setSearchQuery] = useState('')
  const [expandSearch, setExpandSearch] = useState(false)
  
  const searchHandle = ()=>{
    if(searchQuery){
      navigate(`/search/${searchQuery}`)
    }else{
      navigate('/')
    }
  }

    return (
      <header className='sticky-top'>
        <nav className="navbar navbar-expand bg-dark navbar-dark">
        <div className="container-fluid">
          <Link className="navbar-brand text-warning" to="/">
            DailyNewsHunt
          </Link>

          <div className="collapse navbar-collapse">

            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

              <li className="nav-item">
                <div className={`header-search ${expandSearch && 'active'}`}>
                  <div className="input-group">
                    <input type="search" className='form-control bg-dark border-warning text-warning' value={searchQuery} onChange={e=>setSearchQuery(e.target.value)} placeholder='search'/>
                    <button className="btn btn-outline-warning" onClick={searchHandle}>
                      <i className="bi bi-search"></i>
                    </button>
                  </div>      
                    <button className="mobile-search-btn d-md-none btn btn-outline-warning" onClick={()=> setExpandSearch(!expandSearch)}>
                      {
                       expandSearch
                       ? <i className="bi bi-x-lg"></i>
                       : <i className="bi bi-search"></i>
                      }
                    </button>
                </div>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="d-none d-sm-inline-block">Language - </span>
                  <span className='text-capitalize'>{language.name}</span>
                </a>
                <ul className="dropdown-menu pt-0" style={{left:'auto',right:'0', maxHeight:'80vh', overflow:'auto', zIndex:'9000'}}>
                  <li> <div className="p-2 small bg-light text-danger"><strong>LOCAL</strong></div> </li>
                  {
                    languages.map(lang=>
                      lang.type ==='local' && 
                      <li key={lang.shortName}>
                        <button
                         className={`btn dropdown-item ${language.name == lang.name && 'bg-warning rounded-0'}`}
                          onClick={()=>setLanguage({shortName:lang.shortName, name:lang.name})}
                        >
                          {lang.name}
                        </button>
                      </li>
                    )
                  }
                  <li><hr className="dropdown-divider"/></li>
                  <li> <div className="p-2 small bg-light text-danger"><strong> Global </strong></div> </li>                  
                  {
                    languages.map(lang=>
                      lang.type ==='int' && 
                      <li key={lang.shortName}>
                        <button className={`btn dropdown-item ${language.name == lang.name && 'bg-warning rounded-0'}`}
                          onClick={()=>setLanguage({shortName:lang.shortName, name:lang.name})}
                        >
                          {lang.name}
                        </button>
                      </li>
                    )
                  }
                </ul>
              </li>
            </ul>

          </div>
        </div>
      </nav>

      <nav className="navbar navbar-expand nav-scroller bg-body shadow-sm mb-5" style={{    overflowY:'hidden'}}>
      <div className="collapse navbar-collapse" id="navbar">
        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <NavLink className="nav-link" aria-current="page" to="/" end>
              Home
            </NavLink>
          </li>

          {
            categories.map(category=>
              <li key={category} className="nav-item">
                <NavLink to={`/${category}`} className="nav-link text-capitalize" onClick={()=>setSearchQuery('')}>
                  {category}
                </NavLink>
              </li>
              )
          }
        </ul>
        </div>
        </nav>
      </header>
      
    )
  }

export default Navbar