import React, { Component } from 'react'

const Spinner = () => {
      return (
      <>
        <div className="text-center py-5">
            <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <h4>Loading</h4>
        </div>
      </>
    )
  }

export default Spinner